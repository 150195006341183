import React, { useEffect } from "react";

export default function HeroTemplate10() {
  // Function to handle the click event


  
  const trackBannerClick = () => {
    if (typeof window !== "undefined" && window.gtag) {
      // Safe to use window.gtag here
      try {
        console.log(window.gtag);
        window.gtag("event", "click", {
          event_category: "Banner",
          event_label: "365Registration",
          value: 1,
        });
        console.log("google event triggered 2");
      } catch (error) {
        console.error("Error sending GA event:", error);
      }
    }
    
  };

  useEffect(() => {
    // Add event listener
    const link = document.querySelector(
      'a[href="https://www.365cps.org.sg/events/screeningcampaign/my1stmammo/"]'
    );
    if (link) {
      link.addEventListener("click", trackBannerClick);
    }

    // Clean up
    // return () => {
    //   if (link) {
    //     link.removeEventListener('click', trackBannerClick);
    //   }
    // };
  }, []);

  // Rest of your component...
  return (
    <div className="">
      <main>
        <div>
          <div className="relative">
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
                <div className="absolute inset-0 md:hidden">
                  <img
                    className="object-cover w-full h-full "
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1675394836/hero/vrweo2kwdb8oarpmbigj.webp"
                    alt="SWA Hero Image"
                  />
                </div>
                <div className="absolute inset-0">
                  <img
                    className="hidden object-cover w-full h-full md:block"
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1675394836/hero/vrweo2kwdb8oarpmbigj.webp"
                    alt="SWA Hero Image"
                  />
                  <div className="absolute inset-0 bg-swa-2 opacity-30" />
                </div>
                <div className="relative px-4 py-8 sm:px-6 sm:py-16 lg:py-24 lg:px-8">
                  <h1 className="text-4xl font-bold tracking-tight text-center sm:text-5xl lg:text-7xl">
                    <span className="block text-white">
                      Singapore Women's Association
                    </span>
                  </h1>
                  <p className="max-w-lg mx-auto mt-3 text-2xl text-center text-swa-4 sm:max-w-3xl">
                    Celebrating 70th Anniversary
                  </p>

                  <div className="flex justify-center max-w-lg mx-auto mt-6 mb-10 text-xl text-center text-white sm:max-w-2xl">
                    <div>
                      <div className="max-w-sm mx-auto sm:flex sm:max-w-none sm:justify-center">
                        <div className="mt-3 sm:mx-auto">
                          <a
                            href="/what/work"
                            className="flex items-center justify-center px-4 py-3 text-base text-red-700 bg-white border border-transparent rounded-md shadow-sm hover:bg-indigo-50 sm:px-8"
                          
                          >
                            More about our work
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
