import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

// import { GatsbyImage, getImage } from "gatsby-plugin-image";

// import axios from "axios";
import HeroTemplate1 from "./herotemplates/HeroTemplate1";
import HeroTemplate2 from "./herotemplates/HeroTemplate2";
import HeroTemplate3 from "./herotemplates/HeroTemplate3";
import HeroTemplate4 from "./herotemplates/HeroTemplate4";
import HeroTemplate5 from "./herotemplates/HeroTemplate5";
import HeroTemplate6 from "./herotemplates/HeroTemplate6";
import HeroTemplate7 from "./herotemplates/HeroTemplate7";
import HeroTemplate8 from "./herotemplates/HeroTemplate8";
import HeroTemplate9 from "./herotemplates/HeroTemplate9";
import HeroTemplate10 from "./herotemplates/HeroTemplate10";
import HeroTemplate11 from "./herotemplates/HeroTemplate11";
import HeroTemplate12 from "./herotemplates/HeroTemplate12";

const HeroSection = () => {
  const data = useStaticQuery(graphql`
    {
      SWAContent {
        hero {
          description
          imgURL
          link
          embedId
          layout
          status
          title
        }
      }
    }
  `);
  const [hero, setHero] = useState(data.SWAContent.hero[0]);

  // if (hero) {
  //   console.log("hero: ", hero);
  // }

  return (
    <HeroTemplate10 />
    // <>
    //   {hero.layout === "template1" && <HeroTemplate1 hero={hero} />}
    //   {hero.layout === "template2" && <HeroTemplate2 hero={hero} />}
    //   {hero.layout === "template3" && <HeroTemplate3 hero={hero} />}
    //   {hero.layout === "template4" && <HeroTemplate4 hero={hero} />}
    //   {/* <HeroTemplate4 hero={hero} /> */}
    // </>
  );
};

export default HeroSection;

// ----------- graphql -------------------
